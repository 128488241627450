import * as React from 'react';
import { FC } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { IPartnerList } from '../../../../utils/types';
import { ImageGallery } from '../../../ui/imageGallery';

const SectionSuccess: FC<{ translation: TFunction; localKey: string }> = ({
  translation,
  localKey,
}) => {
  const { t } = useTranslation(localKey);
  const list: IPartnerList = t('success.list', {
    returnObjects: true,
  });

  return (
    <section className="section section-success">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8 ">
            <h3
              className="title title-large text-black has-text-centered"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: translation('success.title'),
              }}
            />
          </div>
        </div>
        <div className="columns is-multiline is-centered">
          {Object.keys(list).map((key) => {
            const { icon, text, title, alt } = list[key];
            return (
              <div key={key} className="column content has-text-centered content-tile has-bottom-cta is-marginless key-card">
                <div className="card success-box card-equal-height">
                  <ImageGallery name={icon} alt={alt} className="success-icon" />
                  <p className="partner-text has-text-centered text-white">
                    {text}
                  </p>
                </div>

              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export { SectionSuccess };
