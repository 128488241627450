import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedPageProps, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { SectionSuggestion } from '../components/partials/partner/section-suggestion';
import { SectionSuccess } from '../components/partials/partner/section-success';
import { SectionWhy } from '../components/partials/partner/section-why';
import { SectionInfo } from '../components/partials/partner/section-info';
import { SectionPartner } from '../components/partials/partner/section-community';
import { SectionKeyDemo } from '../components/partials/partner/section-demo';
import { HeroDefault } from '../components/partials/common/hero';
import Trust from '../components/sections/trust';

const ReferralPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const localKey = `${pageContext.key}`;

  const { t } = useTranslation(localKey);
  const { lang, alternateUrls } = pageContext;

  return (
    <Layout pageContext={pageContext}>
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <HeroDefault translation={t} mobileImagesHero={undefined} />
      <SectionPartner />

      <SectionSuggestion translation={t} />
      <Trust />
      <SectionInfo
        localKey={localKey}
        p="highlights"
        classes=" background-new-grey"
        translation={t}
      />
      <SectionInfo
        localKey={localKey}
        p="yourSide"
        classes=""
        translation={t}
      />
      <SectionWhy localKey={localKey} translation={t} />
      <SectionSuccess localKey={localKey} translation={t} />

      <SectionKeyDemo translation={t} />
    </Layout>
  );
};

export default ReferralPage;
