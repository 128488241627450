/* eslint-disable react/no-danger */
import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';

import { Image } from '../../../ui/image';
import { ImageGallery } from '../../../ui/imageGallery';

const SectionWhy: FC<{ translation: TFunction; localKey: string }> = ({
  translation,
  localKey,
}) => {
  const list: any = translation('whyShore.reasons', {
    returnObjects: true,
  });

  return (
    <section className={` section section-why`}>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-9 is-12-mobile">
            <h3
              className="title title-large text-black has-text-centered"
              dangerouslySetInnerHTML={{
                __html: `${translation('whyShore.title')}`,
              }}
            />
          </div>
        </div>
        <div className="columns is-centered">
          {Object.keys(list).map((key) => {
            const { paragraph } = list[key];
            return (
              <div
                key={key}
                className="column is-one-fifth is-12-mobile  has-text-centered"
              >
                <Image
                  name="tick-black.svg"
                  alt={key}
                  className="column-icon"
                />
                <p className="has-text-centered text-medium">{paragraph}</p>
              </div>
            );
          })}
        </div>
        <div className="columns is-centered">
          <div className="column is-9 is-12-mobile">
            <ImageGallery
              name={translation('whyShore.image')}
              alt="inspect why image "
              className=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export { SectionWhy };
