import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { isMobileOnly } from 'react-device-detect';
import images from '../../../../utils/store/images';
import { ImageGallery } from '../../../ui/imageGallery';

const SectionCommunity: FC<{ translation: TFunction }> = ({ translation }) => {
  return (
    <section className="section section-community">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8 ">
            <h2 className="title title-large text-black has-text-centered">
              {translation('community.title')}
            </h2>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-9 ">
            <div className="columns is-centered is-multiline communinity_wrapper">
              {Object.keys(images.community).map((key) => {
                const { icon, classN } = images.community[key];
                return (
                  <div
                    key={key}
                    className={`${
                      !isMobileOnly ? `${classN} column` : ' community-icon '
                    } has-text-centered `}
                  >
                    <ImageGallery
                      name={icon}
                      alt="icon"
                      className="program-icon"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionPartner = () => {
  return (
    <section className="section section-partner">
      <div className="container">
        <div className="columns is-marginless is-multiline is-centered">
          {Object.keys(images.partners).map((key, index) => {
            const { img, alt } = images.partners[key];
            return (
              <div
                key={key}
                className={` column is-one-fifth is-4-mobile has-text-centered is-centered is-vcentered  partner-logo`}
              >
                <ImageGallery name={img} alt={alt} className="partner-icon" />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export { SectionCommunity, SectionPartner };
