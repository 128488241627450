import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import Button from '../../../ui/button';
import { ImageGallery } from '../../../ui/imageGallery';

const SectionRecommendation: FC<{ translation: TFunction }> = ({
  translation,
}) => {
  return (
    <section className="section section-recommend">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-9 ">
            <h2 className="title title-large text-black has-text-centered">
              {translation('sectionRecommend.title')}
            </h2>
            <p className="has-text-centered   text-medium">
              {translation('sectionRecommend.text')}
            </p>

            <div className="columns is-centered">
              <div className="column has-text-centered buttons-container">
                <Button to={translation('sectionRecommend.link')} >
                  {translation('sectionRecommend.startNowButton')}
                </Button>
              </div>
            </div>
            <ImageGallery
              name={translation('sectionRecommend.image')}
              alt={translation('sectionRecommend.alt')}
              className="has-text-centered recommendation-img"
            />
            <div className="columns is-centered">
              <div className="column has-text-centered buttons-container">
                <p className="small-note">
                  {translation('sectionRecommend.note')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionSuggestion: FC<{ translation: TFunction }> = ({ translation }) => {
  return (
    <section className="section section-suggestion gradient-gray">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-6 is-centered is-vcentered vertical-align">
            <div className=" card-equal-height background-purple card-container">
              <h4 className="title title-medium has-text-white ">
                {translation('suggestion.options.option_1.title')}
              </h4>
              <p className=" text-white text-medium">
                {translation('suggestion.options.option_1.text')}
              </p>
              <ImageGallery
                name={translation('suggestion.options.option_1.image')}
                alt={translation('suggestion.options.option_1.alt')}
                className="recommendation-img bottom-image is-hidden-mobile "
              />
            </div>
          </div>
          <div className="column is-6 is-centered is-vcentered vertical-align">
            <div className="card-equal-height background-primary card-container">
              <h4 className="title title-medium has-text-white ">
                {translation('suggestion.options.option_2.title')}
              </h4>
              <p className=" text-white text-medium">
                {translation('suggestion.options.option_2.text')}
              </p>

              {translation('suggestion.options.option_2.image') && (
                <ImageGallery
                  name={translation('suggestion.options.option_2.image')}
                  alt={translation('suggestion.options.option_2.alt')}
                  className="recommendation-img bottom-image is-hidden-mobile "
                />
              )}
            </div>
          </div>
        </div>
        <div className="buttons is-centered">
          <Button
            to={translation('suggestion.ctaTryUrl')}
            size="large"
            className="is-key"
          >
            {translation('suggestion.ctaTryText')}
          </Button>
          <Button
            to={translation('suggestion.ctaTryUrl')}
            size="large"
            className="is-key"
          >
            {translation('suggestion.ctaDemoText')}
          </Button>
        </div>
      </div>
    </section>
  );
};

export { SectionRecommendation, SectionSuggestion };
