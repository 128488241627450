import * as React from 'react';
import { FC } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Image } from '../../../ui/image';
import { IPartnerList } from '../../../../utils/types';

const SectionInfo: FC<{
  translation: TFunction;
  localKey: string;
  p: string;
  classes: string;
}> = ({ translation, localKey, p, classes }) => {
  const { t } = useTranslation(localKey);
  const list: IPartnerList = t(`${p}.list`, {
    returnObjects: true,
  });

  return (
    <section className={`${classes} section section-info`}>
      <div className="container">
        {translation(`${p}.image`) !== `${p}.image` && (
          <div className="columns is-centered image-head">
            <div className="column is-12 is-centered">
              <div className="image-section has-text-centered is-centered">
                <Image
                  name={translation(`${p}.image`)}
                  alt={translation(`${p}.alt`)}
                  className=" is-centered"
                  height="200px"
                  width="200px"
                />
              </div>
              {translation(`${p}.email`) !== `yourSide.email` && (
                <div className=" is-centered has-text-centered ">
                  <div className="inline">Tel. {translation(`${p}.tel`)}</div>
                  <div className="inline">
                    E-Mail. {translation(`${p}.email`)}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="columns is-centered">
          <div className="column is-8 ">
            <h3
              className="title title-large text-black has-text-centered"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: translation(`${p}.title`),
              }}
            />
          </div>
        </div>
        <div className="columns is-multiline is-centered">
          {Object.keys(list).map((key) => {
            const { icon, text, heading, alt } = list[key];
            return (
              <div
                key={key}
                className="column  content has-text-centered content-tile has-bottom-cta is-marginless key-card"
              >
                <div className="card card-equal-height">
                  <Image name={icon} alt={alt} className="success-icon" />
                  <h4 className="text-black">
                    <b>{heading}</b>
                  </h4>
                  <p className="text-black">{text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export { SectionInfo };
