import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import HubspotForm from 'react-hubspot-form';
import images from '../../../../utils/store/images';
import { ImageGallery } from '../../../ui/imageGallery';
import { Image } from '../../../ui/image';

const SectionDemo: FC<{ translation: TFunction }> = ({ translation }) => {
  return (
    <section className="section section-conversion" id="sales">
      <div className="container">
        <div className="columns is-centered position-static">
          <div className="column is-8 ">
            <h2 className="title title-large has-text-centered has-text-white">
              {translation('demo.title')}
            </h2>
          </div>
        </div>
        <div className="columns is-desktop is-centered position-relative">
          <div className="column is-three-quarters-desktop is-centered has-background-white demo-box">
            <div className="hbspt-form-wrapper">
              <div className="hb-target hbspt-form" id="hbspt-form-id">
                <HubspotForm
                  portalId={translation('demo.form.portalId')}
                  formId={translation('demo.form.formId')}
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionKeyDemo: FC<{ translation: TFunction }> = ({ translation }) => {
  const leftList: any = translation('demo.card.leftList', {
    returnObjects: true,
  });
  const { shoreKeyAccountPeople } = images;
  return (
    <section className="section section-conversion" id="sales">
      <div className="container">
        <div className="columns is-centered position-static">
          <div className="column is-8 ">
            <h2 className="title title-large has-text-centered has-text-white">
              {translation('demo.title')}
            </h2>
          </div>
        </div>
        <div className="columns is-centered position-relative">
          <div className="column is-11-desktop  has-background-white conversion-wrapper is-centered">
            <div className="columns">
              <div className="column web-demo is-half  ">
                <h3 className="title title-medium text-black">
                  {translation('demo.card.title')}
                </h3>
                <p className="has-text-left">
                  {translation('demo.card.leftText')}
                </p>
                <ul>
                  {Object.keys(leftList).map((key) => {
                    return (
                      <li key={key}>
                        <Image
                          name="check_black.svg"
                          className="inline-svg inline-icon"
                          alt="shore.com"
                          width="20px"
                          height="20px"
                        />
                        {leftList[key]}
                      </li>
                    );
                  })}
                </ul>
                <div className="image-container image-list">
                  {/* 
                  temp removed
                  
                  {Object.keys(shoreKeyAccountPeople).map((key: any) => {
                    return (
                      <div key={key}>
                        <ImageGallery
                          name={shoreKeyAccountPeople[key]}
                          alt="we support you"
                          className="sales_person"
                          maxWidth="70"
                          height="70"
                        />
                      </div>
                    );
                  })} */}
                </div>
              </div>
              <div className="column web-demo is-half right-half  ">
                <div className="hbspt-form-wrapper">
                  <div className="hb-target hbspt-form" id="hbspt-form-id">
                    <HubspotForm
                      portalId={translation('demo.card.form.portalId')}
                      formId={translation('demo.card.form.formId')}
                      loading={<div>Loading...</div>}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { SectionDemo, SectionKeyDemo };
